const uses = {
  'Desk Setup': {
    Computer: {
      title: `2018 15" MacBook Pro with a 2.9 GHz Intel Core i9 and
      32 GB of ram`,
    },
    Keyboard: {
      title: `Kinesis Freestyle2 Blue Wireless Keyboard`,
      link: `https://www.amazon.com/Freestyle2-Wireless-Ergonomic-Keyboard-Separation/dp/B00NMVJZ1E`,
    },
    Mouse: {
      title: `Anker AK-UBA 2.4G Wireless Vertical Mouse`,
      link: `https://www.amazon.com/Anker-Wireless-Vertical-Ergonomic-Optical-x/dp/B00BIFNTMC`,
    },
    Headphones: {
      title: `Apple AirPods Pro`,
    },
    'Laptop Stand': {
      title: `Rain Design mStand`,
      link: `https://www.amazon.com/Rain-Design-mStand-Laptop-Patented/dp/B000OOYECC`,
    },
    Monitor: {
      title: `LG 27" 5K Ultrafine`,
      link: `https://www.amazon.com/LG-27MD5KB-B-UltraFine-Brightness-Thunderbolt-x/dp/B0778Z8G5K`,
    },
    Webcam: {
      title: `Logitech HD Pro Webcam c920`,
      link: `https://www.amazon.com/Logitech-Widescreen-Calling-Recording-Desktop/dp/B006JH8T3S`,
    },
    Desk: {
      title: `72" Uplift v2 Standing Desk`,
      link: `https://www.upliftdesk.com/adjustable-height-desks/`,
    },
    Chair: {
      title: `MARKUS from IKEA`,
      link: `https://www.ikea.com/us/en/p/markus-office-chair-glose-black-robust-black-00103102/`,
    },
  },
  Applications: {
    'For password and login management...': {
      title: `1Password`,
      link: `https://1password.com/`,
    },
    'For launching apps, text snippets, etc...': {
      title: `Alfred`,
      link: `https://www.alfredapp.com/`,
    },
    'For keeping my Mac awake...': {
      title: `Amphetamine`,
      link: `https://apps.apple.com/us/app/amphetamine/id937984704?mt=12`,
    },
    'For backing up my computer...': {
      title: `Backblaze`,
      link: `https://www.backblaze.com/`,
    },
    'For keeping my Mac menu bar nice and clean...': {
      title: `Bartender`,
      link: `https://www.macbartender.com/`,
    },
    'For keyboard, trackpad, and Touchbar modifications...': {
      title: `BetterTouchTool`,
      link: `https://folivora.ai/`,
    },
    'For maintenance and keeping my computer running smooth...': {
      title: `CleanMyMac`,
      link: `https://macpaw.com/cleanmymac-3`,
    },
    'For local code and technical documentation...': {
      title: `Dash`,
      link: `https://kapeli.com/dash`,
    },
    'For all my calendar needs...': {
      title: `Fantastical`,
      link: `https://flexibits.com/fantastical`,
    },
    'For all my design needs...': {
      title: `Figma`,
      link: `https://www.figma.com/`,
    },
    'For quick and easy timers while working...': {
      title: `Gestimer`,
      link: `http://maddin.io/gestimer/`,
    },
    'For capturing GIFs...': {
      title: `Gifox`,
      link: `https://gifox.io/`,
    },
    'For seeing statistics on my computer...': {
      title: `iStat Menus`,
      link: `https://bjango.com/mac/istatmenus/`,
    },
    'For the terminal...': {
      title: `iTerm`,
      link: `https://www.iterm2.com/`,
    },
    'For tons of workflow-efficiency needs...': {
      title: `Keyboard Maestro`,
      link: `https://www.keyboardmaestro.com/main/`,
    },
    'For window management...': {
      title: `Moom`,
      link: `https://manytricks.com/moom/`,
    },
    'For all goals, projects, tasks, notes, and more...': {
      title: `Notion`,
      link: `https://www.notion.so/`,
    },
    'For quitting apps automatically...': {
      title: `Quitter`,
      link: `https://marco.org/apps`,
    },
    'For tracking how I spend my time in the background...': {
      title: `RescueTime`,
      link: `https://www.rescuetime.com/`,
    },
    'For a strong emoji game 💪...': {
      title: `Rocket`,
      link: `https://matthewpalmer.net/rocket/`,
    },
    'For picking colors...': {
      title: `Sip`,
      link: `https://sipapp.io/`,
    },
    'For managing databases...': {
      title: `TablePlus`,
      link: `https://tableplus.com/`,
    },
    'For quickly switching Bluetooth devices...': {
      title: `ToothFairy`,
      link: `https://c-command.com/toothfairy/`,
    },
    'For rare FTP-ing..': {
      title: `Transmit`,
      link: `https://panic.com/transmit/`,
    },
    'For coding..': {
      title: `Visual Studio Code`,
      link: `https://code.visualstudio.com/`,
    },
  },
}

export default uses
