import React from 'react'

import uses from '../config/uses'

import Box from '../styled/Box'
import Layout from '../components/Layout'
import List from '../styled/List'
import PageHeader from '../components/PageHeader'
import SEO from '../components/SEO'

const UsesPage = () => (
  <Layout maxWidth="800px">
    <SEO title="Uses" />
    <PageHeader
      title="Uses"
      description="My workspace and the applications I use."
    />
    {Object.keys(uses).map((group, idx) => (
      <Box key={idx} mb="xl">
        <Box as="h2" mb="base">
          {group}
        </Box>
        <List
          as="dd"
          borderTop="1px solid"
          borderColor="border"
          listStyle="none"
          ml="0"
          pt="base"
        >
          {Object.keys(uses[group]).map((item, _idx) => (
            <List.Item
              key={_idx}
              as="div"
              borderBottom="1px solid"
              borderColor="border"
              pb="base"
            >
              <Box as="dt" color="medium" display="block" fontSize="xs">
                <Box as="strong">{item}</Box>
              </Box>
              <Box as="dd">
                {uses[group][item].link ? (
                  <Box as="a" href={uses[group][item].link}>
                    {uses[group][item].title}
                  </Box>
                ) : (
                  uses[group][item].title
                )}
              </Box>
            </List.Item>
          ))}
        </List>
      </Box>
    ))}
    <Box as="h2">Dotfiles</Box>
    <Box as="p">
      I’m obsessive about efficiency, productivity, and the ability to easily
      spin up a new machine with all of the little details as I like them. So
      that’s why I have{' '}
      <Box as="a" href="https://github.com/drewbarontini/dotfiles">
        extensive dotfiles
      </Box>{' '}
      that I maintain. All of my nitty-gritty settings for my computer
      (development, specifically) are maintained there.
    </Box>
  </Layout>
)

export default UsesPage
