import styled from 'styled-components'
import themeGet from '@styled-system/theme-get'

import { system } from './lib/system'

const List = styled.ul`
  margin-left: ${themeGet('space.l')};

  ${system}
`

const ListItem = styled.li`
  &:not(:last-child) {
    margin-bottom: ${themeGet('space.s')};
  }

  ${system}
`

List.Item = ListItem

export default List
